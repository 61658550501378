import React, {useEffect, useState} from "react";
import {Button, Typography, Divider, Card, CardContent} from "@material-ui/core";
import {ListDrugs} from "../listDrugs"
import {useHistory} from "react-router-dom";
import {ChoiceRoleModal} from "../DialogsComponents/choiceRoleModal"
import {makeStyles} from "@material-ui/core/styles";
import {useHttp} from "../../../Hooks/useHttp";
import {Loader} from "../../../Helpers/Loader";
import {$drugs, getDrugsRequest} from "../../../Stores/DrugsStore";
import {$selectedDrugs} from "../../../Stores/SelectedDrugsStore";
import {paths} from "../../../Helpers/paths";
import {useStore} from "effector-react";
import {UserNavBar} from "../UserNavBar";

const useStyles = makeStyles((theme) => ({
    rowLists: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: theme.spacing(2)
    },

    titleClass: {
        margin: theme.spacing(2)
    },

    btn: {
        backgroundColor: '#00afb6',
        color: "white",
        "&:hover": {
            backgroundColor: '#02a5ab'
        }
    },

    divBtn: {
        margin: theme.spacing(4),
        float: "right"
    }
}));

export function ChoiceDrugs() {

    const history = useHistory();
    const styles = useStyles();
    const {loading, request} = useHttp()

    const [itemsOncology, setItemsOncology] = useState([]);
    const [itemsCardiology, setItemsCardiology] = useState([]);

    const drugs = useStore($drugs)
    const selectedDrugs = useStore($selectedDrugs);

    useEffect(() => {
        getDrugsRequest({request})
    }, [request]);

    useEffect(() => {
        setItemsOncology(drugs.filter(itemData => itemData.medType.id === 1));
        setItemsCardiology(drugs.filter(itemData => itemData.medType.id === 2));
    }, [drugs])

    const isButtonDisabled = (selectedDrugs.filter(item => item.medType.id === 1).length === 0 ||
        selectedDrugs.filter(item => item.medType.id === 2).length === 0);

    return (
        <>
            <UserNavBar/>
            {loading ? <Loader message={"Загрузка данных, подождите..."}/> :
                <Card elevation={4}>
                    <CardContent>
                        <ChoiceRoleModal/>
                        <div>
                            <Typography className={styles.titleClass} variant={"h5"}>
                                Выберете лекарства, для которых Вы хотите проверить взаимодействие:</Typography>

                            <div className={styles.rowLists}>
                                <ListDrugs items={itemsOncology} title="Онкологические лекарства"/>
                                <Divider orientation="vertical" flexItem/>
                                <ListDrugs items={itemsCardiology} title="Кардиологические лекарства"/>
                            </div>

                            <div className={styles.divBtn}>
                                <Button className={styles.btn} variant="contained"
                                        onClick={() => (history.push({
                                            pathname: paths.toInteractions,
                                            state: selectedDrugs
                                        }))}
                                        disabled={isButtonDisabled}>Узнать о взаимодействиях</Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>}
        </>
    );
}

export default ChoiceDrugs;