import React, {useEffect, useState} from "react";
import {
    List,
    TextField,
    Typography,
    FormControlLabel, Switch
} from "@material-ui/core";
import {useStore} from 'effector-react'
import {$selectedDrugs} from "../../Stores/SelectedDrugsStore";
import {makeStyles} from "@material-ui/core/styles";
import {$selectedRole} from "../../Stores/RolesStore";
import {MedClassView} from "./ListDrugsComponent/MedClassView";
import {DrugListItem} from "./ListDrugsComponent/DrugListItem";

const useStyles = makeStyles((theme) => ({
    listComponentClass:{
        display: "block",
        width: "100%",
        margin: theme.spacing(2)
    },
    list:{
        minHeight: "24rem",
        maxHeight: "24rem",
        overflow: 'auto'
    },
    listTitle:{
        marginLeft: theme.spacing(1)
    },
    inputLine:{
        margin: theme.spacing(1.5),
        width: "70%"
    }
}));


export function ListDrugs({title, items} ){

    const style = useStyles();

    const [str, setStr] = useState('');
    const [classView, setClassView] = useState(false)
    
    let selectedItems = useStore($selectedDrugs)
    const selectedRole = useStore($selectedRole)

    const [sortedItems, setSortedItems] = useState({})
    const [openField, setOpenField] = useState({})

    useEffect(() => {
        let newObj = items.reduce((res, elem) => {
            if(elem.medClass !== null){
                res[elem.medClass.name] = res[elem.medClass.name] || []
                res[elem.medClass.name].push(elem)
            }else {
                res["Не назначено"] = res["Не назначено"] || []
                res["Не назначено"].push(elem)
            }
            return res
        }, Object.create(null))


        let openedObj = Object.keys(newObj).reduce((res, elem) => {
            res[elem] = false
            return res
        }, Object.create(null))

        setSortedItems(newObj)
        setOpenField(openedObj)
    },[items])

    const handleCheck = () => setClassView(!classView)

    items = items.filter(value => value.name?.toLowerCase().includes(str.toLowerCase())
        || value.tradeNameList?.join(', ').toLowerCase().includes(str.toLowerCase()));

    return(
        <div className={style.listComponentClass}>
            <Typography className={style.listTitle} variant={"h6"}>
                {title}
            </Typography>
            {selectedRole?.id !== 3 && <FormControlLabel
                control={<Switch color={"primary"} onChange={handleCheck} checked={classView}/>}
                label="Классовое представление"
            />}

            {!classView && <TextField
                className={style.inputLine}
                id="standard-basic"
                label="Поиск..."
                onChange={event => setStr(event.target.value)}
            />}
            <List className={style.list}>
                {!classView ? items.map((value, index) => (
                    <DrugListItem
                        key={`not-sorted-drug-item-${index}-${value.name}`}
                        selectedRole={selectedRole}
                        value={value}
                        selectedItems={selectedItems}
                    />
                )): <MedClassView
                    selectedRole={selectedRole}
                    selectedItems={selectedItems}
                    items={sortedItems}
                    opens={openField}
                    setOpens={(item) => setOpenField(item)}
                />
                }
            </List>
        </div>
    )
}