import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from '@material-ui/icons/Add';
import React, {useState} from "react";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {DeleteDrugDialog} from "../DeleteDrugDialog";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    addRowBtn: {
        float: "right",
        backgroundColor: "#00ADB5",
        color: "white",
        margin: theme.spacing(1),
        width: "9rem",
        "&:hover": {
            backgroundColor: '#02a5ab'
        }
    },
    divAddBtn: {
        width: "100%"
    }
}));

// Верхняя часть таблицы
export function TableDrugsToolbar({numSelected, setAddMode, deleteSelected}) {

    const classes = useToolbarStyles()
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <>
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} выбрано
                    </Typography>

                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" onClick={() => setShowDeleteModal(true)}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </>
            ) : (
                <div className={classes.divAddBtn}>
                    <Button
                        className={classes.addRowBtn}
                        onClick={() => setAddMode()}
                        endIcon={<AddIcon/>}
                    >
                        Добавить
                    </Button>
                </div>
            )}
            <DeleteDrugDialog show={showDeleteModal}
                              close={() => setShowDeleteModal(false)}
                              deleteFunc={() => deleteSelected()}
                              title="Предупреждение"
                              message={`Вы уверены что хотите удалить выбранные препараты (${numSelected})?`}
            />
        </Toolbar>
    );
}