import React, {useState} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {DeleteDrugDialog} from "../DeleteDrugDialog";
import {removeDrugsRequest} from "../../../Stores/DrugsStore";
import {useHttp} from "../../../Hooks/useHttp";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        height:'20px',
    },
    tableCell: {
        fontStyle: 'normal',
        fontSize: 14
    },
    medTypeCell: {
        border: "1px solid",
        fontStyle: 'normal',
        fontSize: 14,
        padding: theme.spacing(0.7),
        borderRadius: "10px"
    },
    tradeNameCell:{
        height:'40px',
        overflowY: 'scroll',
    }
}));


export function TableDrugsRow(props) {

    const {row, isItemSelected, handleClickCheckbox, addEditRow} = props
    const classes = useStyles()
    const {request} = useHttp()

    //Открыто ли окно удаления
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const medTypeStyle = row.medType === "онкологическое" ?
        {
            color: "#00ADB5",
            borderColor: "#80D6DA",
            backgroundColor: "#E5F7F8"
        } :
        {
            color: "#393E46",
            borderColor: "#9C9EA3",
            backgroundColor: "#EBECEC"
        }

        const deleteDrug = (row) => {
            removeDrugsRequest({request: request, listIdRemove: [row.id]})

            if (isItemSelected) {
                handleClickCheckbox(row.id)
            }
        }

    const handleClickDeleteButton = () => {
        setShowDeleteModal(true)
    };

    return (

        <TableRow
            hover
            aria-checked={isItemSelected}
            key={row.id}
            selected={isItemSelected}
            className={classes.tableRow}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    checked={isItemSelected}
                    onClick={() => handleClickCheckbox(row.id)}
                />
            </TableCell>

            <TableCell className={classes.tableCell} align="left">{row.name}</TableCell>
            <TableCell className={classes.tableCell} align="left">{row.group}</TableCell>
            <TableCell className={classes.tableCell} align="left">
                <div className={classes.tradeNameCell}>
                    {row.tradeName !== null ? row.tradeName.join('\n') : "Не указано"}
                </div>
            </TableCell>
            <TableCell align="center">
                <Typography
                    className={classes.medTypeCell}
                    style={medTypeStyle}
                >
                    {row.medType}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell} align="left">{row.medClass?.name}</TableCell>
            <TableCell>
                <IconButton aria-label="edit" value={row.id} onClick={() => addEditRow(row)}>
                    <EditIcon/>
                </IconButton>
                <IconButton aria-label="delete" onClick={() => handleClickDeleteButton(row)}>
                    <DeleteIcon/>
                </IconButton>
            </TableCell>
            <DeleteDrugDialog show={showDeleteModal}
                              close={() => setShowDeleteModal(false)}
                              deleteFunc={() => deleteDrug(row)}
                              title="Предупреждение"
                              message={`Вы уверены что хотите удалить ${row.name}?`}
            />
        </TableRow>

    );
}