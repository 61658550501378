import {createStore} from "effector/compat";
import {createEvent} from "effector";

// Изменения хранилища выбранных лекарств
export const selectDrug = createEvent();
export const unselectDrug = createEvent();

// Выбранные лекарства
export const $selectedDrugs = createStore([])
    .on(selectDrug, (state,value) => [...state, value])
    .on(unselectDrug, (state, value) => state.filter(item => item.id !== value.id));