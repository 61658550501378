import React, {useEffect, useState} from 'react'
import {VerticalMenu} from '../VerticalMenu'
import {makeStyles, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useHttp} from "../../../Hooks/useHttp";
import {$drugs, getDrugsRequest} from "../../../Stores/DrugsStore";
import {AdminDrugsTable} from "../AdminDrugsTable/AdminTableDrugs";
import {Loader} from "../../../Helpers/Loader";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import {useStore} from "effector-react";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    title: {
        marginLeft: "1.5rem",
        fontSize: 38,
        display: "flex",
        direction: "row"
    },
    searchLine: {
        marginLeft: "4rem",
        width: "17rem"
    }
}));

export function AdminDrugs() {

    const classes = useStyles()
    const {loading, request} = useHttp()
    const [searchedDrug, setSearchedDrug] = useState('')

    const [rows, setRows] = useState([])
    const drugs = useStore($drugs)

    // Запрос на получение всех медикаментов
    useEffect(() => {
        getDrugsRequest({request})
    }, [request]);

    useEffect(() => {
        setRows(drugs.map((value) => {
            return {
                id: value.id,
                name: value.name,
                group: value.group.trim() || "Не указана",
                tradeName: value.tradeNameList,
                medType: value.medType.name,
                medClass: value.medClass ? value.medClass : "Не указано"
            }
        }))
    }, [drugs])

    return (
        <div className={classes.root}>
            <VerticalMenu/>

            <main className={classes.content}>

                <div className={classes.title}>
                    <Typography
                        variant="h3"
                        component="h3"
                        gutterBottom>
                        Лекарства
                    </Typography>

                    <TextField id="standard-search"
                               label="Поиск по названию..."
                               className={classes.searchLine}
                               onChange={event => setSearchedDrug(event.target.value)}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <SearchIcon/>
                                       </InputAdornment>
                                   ),
                               }}
                    />
                </div>

                <div style={{height: "30rem", width: '100%', marginTop: "1.5rem"}}>
                    {loading ? <Loader/> : <AdminDrugsTable listDrugs={rows} filterString={searchedDrug}/>}
                </div>
            </main>
        </div>
    )
}