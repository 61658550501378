// Типы ролей
import {createStore} from "effector/compat";
import {createEvent} from "effector";

// Выбрать роль
export const selectRole = createEvent();

export const $roles = createStore([
    {id: 1, title: "Онколог"},
    {id: 2, title: "Кардиолог"},
    {id: 3, title: "Пациент"}]);

// Выбранная роль
export const $selectedRole = createStore(null)
    .on(selectRole, (state, value) => state = value);