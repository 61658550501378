import React, {useState} from "react";
import {IconButton, TableCell, Typography} from "@material-ui/core";
import {IconInteraction} from "../iconInterction";
import Collapse from "@material-ui/core/Collapse";
import {$selectedRole} from "../../../Stores/RolesStore";
import {useStore} from "effector-react";
import {tableStyles} from "./TableStyles"


export function InteractionCell({interaction}){

    const classes = tableStyles();
    const [show, setShow] = useState(false);

    const selectedRole = useStore($selectedRole);

    const descriptions = interaction !== null? [interaction.oncologistDescription,
        interaction.cardiologistDescription,
        interaction.patientDescription]: [];

    return(
        <React.Fragment>
            <TableCell align={"center"} className={classes.tableCeil}>
                <IconButton className={classes.iconButtonClass} onClick={() => {setShow(!show)}}>
                    <IconInteraction levelInteractionId={interaction===null? 1: interaction.interactionLvlDto.id}/>
                </IconButton>
                <Collapse in={show} timeout="auto" unmountOnExit>
                    <Typography align={"center"} variant="body1" gutterBottom>
                        {selectedRole === null || interaction === null || descriptions[selectedRole.id - 1] === null?
                            "Полная информация о взаимодействии не найдена": descriptions[selectedRole.id - 1]}
                    </Typography>
                </Collapse>
            </TableCell>
        </React.Fragment>
    )
}