
// Авторизирован ли админ
import {createStore} from "effector/compat";
import {createEvent} from "effector";
import connectLocalStorage from "effector-localstorage";

const authorizationLocalStorage = connectLocalStorage("isAuthorization")
    .onError((err) => console.log(err))

// Изменения статуса авторизации
export const loginAdmin = createEvent();
export const logoutAdmin = createEvent();

export const $AdminAuthorization = createStore( authorizationLocalStorage.init() || false)
    .on(loginAdmin, (state) => state = true)
    .on(logoutAdmin, (state) => state = false)

$AdminAuthorization.watch(authorizationLocalStorage)
