import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import {logoutAdmin} from "../../Stores/AdminAuthorizationStore";
import {paths} from "../../Helpers/paths";
import {useHistory} from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import med_logo from "../../med-logo-white.svg";
import interactions_logo_white from "../../interactions-white.svg"
import interactions_logo_black from "../../interactions-black.svg"
import drugs_logo_white from "../../drugs-white.svg";
import drugs_logo_black from "../../med-logo-black.svg";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 24,
        fontFamily: 'Roboto',
        color: "white",
        marginLeft: theme.spacing(1)
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#00ADB5",
        border: "none"
    },
    titleDiv:{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(15)
    },
    listButtons:{
        marginTop: theme.spacing(10)
    },
    routeBtnText:{
        fontSize: 20,
        fontFamily: 'Roboto',
        textTransform: 'none'
    },
    logo:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    footer:{
        position: "fixed",
        bottom: 0,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(3)
    }
}));

export function VerticalMenu() {

    const history = useHistory()
    const classes = useStyles()

    return (
        <div>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.titleDiv}>
                    <img src={med_logo} alt="logo" />
                    <Typography className={classes.title} variant="h5">
                        B2DOC MED
                    </Typography>
                </div>

                <List component="nav" className={classes.listButtons}>
                    <ListItem button
                              onClick={() => (history.push(paths.toAdminDrugs))}
                              style={{backgroundColor: window.location.pathname === paths.toAdminDrugs && "white"}}
                              >
                        <ListItemIcon className={classes.logo}>
                            <img src={window.location.pathname === paths.toAdminDrugs ? drugs_logo_black : drugs_logo_white}/>
                        </ListItemIcon>
                        <ListItemText primary="Лекарства"
                                      classes={{primary:classes.routeBtnText}}
                                      style={{color: window.location.pathname === paths.toAdminDrugs ? "#393E46": "white"}}/>
                    </ListItem>
                    <ListItem button
                              onClick={() => (history.push(paths.toAdminInteractions))}
                              style={{backgroundColor: window.location.pathname === paths.toAdminInteractions && "white"}}
                              >
                        <ListItemIcon className={classes.logo}>
                            <img src={window.location.pathname === paths.toAdminInteractions ? interactions_logo_black : interactions_logo_white}/>
                        </ListItemIcon>
                        <ListItemText primary="Взаимодействия"
                                      classes={{primary:classes.routeBtnText}}
                                      style={{color: window.location.pathname === paths.toAdminInteractions ? "#393E46": "white"}}/>
                    </ListItem>
                </List>

                <div className={classes.footer}>
                    <Button onClick={() => {
                        logoutAdmin();
                        history.push(paths.toLogin);
                    }}
                            className={classes.routeBtnText}
                            style={{color: "white"}}
                            startIcon={<PowerSettingsNewIcon style={{ fontSize: 24 }} />}>Выход</Button>
                </div>
            </Drawer>
        </div>
    )
}