import React from "react";
import {Typography, CircularProgress, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    progressClass:{
        color: "#00afb6",
        marginRight: theme.spacing(3)
    },
    divLoader: {
        display: "flex",
        float: "center",
        justifyContent: "center",
        height: "auto",
        width: "auto"
    }
}));

export function Loader({message}){
    const classes = useStyles();

    return(
        <div className={classes.divLoader}>
            <CircularProgress className={classes.progressClass}/>
            <Typography variant={"h5"}>{message}</Typography>
        </div>
    )
}