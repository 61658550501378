import React, {useEffect, useState} from "react";
import {Button, CardContent, Typography, Card, makeStyles} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import {useHttp} from "../../../Hooks/useHttp";
import {TableInteractions} from "../ComponentsOfTable/tableDrugInteractions"
import {Loader} from "../../../Helpers/Loader";
import {paths} from "../../../Helpers/paths";
import {ArrowBack} from "@material-ui/icons";
import {LineIcons} from "../lineIconsValues";
import {UserNavBar} from "../UserNavBar";

const useStyles = makeStyles((theme) => ({
    backBtn: {
        marginBottom: theme.spacing(1.5)
    },
    titleClass:{
        margin: theme.spacing(1.5)
    }
}));

export function DrugInteraction() {

    const styles = useStyles();
    const history = useHistory();
    const {loading, request} = useHttp();
    const location = useLocation();
    const selectedDrugsList = location.state ? location.state : [];

    const requestBody = {
        "medOneName": selectedDrugsList.filter(item => item.medType.id === 1).map(item => item.name),
        "medTwoName": selectedDrugsList.filter(item => item.medType.id === 2).map(item => item.name)
    };

    const [interactions, setInteractions] = useState([]);
    const [levelsInteractions, setLevelInteractions] = useState([]);

    useEffect(() => {
        request("/med_interaction/getMedInteractions", "POST", requestBody, btoa("420:legit"))
            .then(data => {
                setInteractions(data);

            })
            .catch(() => setInteractions([]));

        request("/interaction_level/", "GET", null, btoa("420:legit"))
            .then(data => {
                setLevelInteractions(data);
            }).catch(() => setLevelInteractions([]));

    },[]);

    return(
        <>
            <UserNavBar />
            {loading ? <Loader message={"Загрузка данных, подождите..."}/> :
                <>
                <Button onClick={() => (history.push(paths.toChoice))}
                        startIcon={<ArrowBack/>}
                        className={styles.backBtn}>Выбор лекарств</Button>
                <Card elevation={4}>
                    <CardContent>
                        <Typography variant={"h5"}
                                    className={styles.titleClass}>Таблица лекарственных взаимодействий</Typography>
                        <Typography variant="subtitle1"
                                    className={styles.titleClass}>Чтобы узнать подробнее о взаимодействии нажмите на
                            значок</Typography>
                        <LineIcons levelsInteractions={levelsInteractions}/>
                        <TableInteractions selectedDrugs={selectedDrugsList} drugsInteractions={interactions}/>
                    </CardContent>
                </Card>
                </>}
        </>
    );
}