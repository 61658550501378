import React, { useCallback } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function useMessage() {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    // <'success' | 'error' | 'warning' | 'info'>
    const [severity, setSeverity] = React.useState('success');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    const openSnack = useCallback((customSeverity, customMessage) => {
        setOpen(true);
        setMessage(customMessage);
        setSeverity(customSeverity);
    }, []);

    const snack = (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                  anchorOrigin={{vertical:"top", horizontal: "right" }}>
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );

    return { snack, openSnack };
}
