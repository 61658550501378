import React, {useEffect, useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import {Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {useHttp} from "../../Hooks/useHttp";
import { createDrugRequest, updateDrugRequest} from "../../Stores/DrugsStore";
import drugs from '../../new-drags.json';
import useMessage from "../../Hooks/useMessage";
import {Search} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    inputField: {
        marginBottom: theme.spacing(4),
        paddingRight: theme.spacing(2),
        width: "100%"
    },
    content: {
        display: "flex",
        flexDirection: "column"
    },
    selectForm:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: "100%"
    },
    scrollableDiv:{
        height:'150px',
        overflowY: 'scroll',
        paddingLeft: theme.spacing(1)
    }
}));

export function EditDrugModal({isOpen, close, currentEditDrug, changeDrug}){

    const classes = useStyles()
    const [tradeNames, setTradeName] = useState([]);
    const {loading, request} = useHttp()
    const {snack, openSnack } = useMessage()
    const [medClasses, setMedClasses] = useState([])

    useEffect(() => {
        request("/api/medicine/medClasses", "GET", null, btoa("420:legit"))
            .then(data => setMedClasses(data))
            .catch(() => openSnack('warning', 'Не удалось подключится к серверу. Проверьте подключение!'))

        return () => setTradeName([])
    },[])


    const handleChangeDescription = event => {
        changeDrug({...currentEditDrug, [event.target.name]: event.target.value})
    }

    const getAllTradeNames = async () => {
        if(currentEditDrug.name){
            try{
                const names = await JSON.parse(JSON.stringify(drugs))
                let arr = names.filter(elem => currentEditDrug.name.toLowerCase() === elem.name.toLowerCase())
                    .map(elem => elem.tradeMark).filter((v, i, a) => a.indexOf(v) === i)

                if(!arr.length)
                    openSnack('warning', 'В базе нет такого препарата!')

                setTradeName(arr)
            }catch (e) {
                openSnack('warning', 'В базе нет такого препарата!')
            }
        }else {
            openSnack('warning', 'Введите название препарата!')
        }
    }

    const handleSaveButtonClick = () => {
        console.log(currentEditDrug.medClass)
        let requestBody = {
            group: currentEditDrug.group,
            medTypeId: currentEditDrug.medType === "онкологическое" ? 1 : 2,
            name: currentEditDrug.name,
            medClassId: currentEditDrug.medClass === "Не указано" ? null : currentEditDrug.medClass
        }

        if(currentEditDrug.medClass === "Не указано"){
            openSnack('warning', 'Класс медикамента обязательное поле!')
            return
        }

        // Поменять на id
        if (currentEditDrug.id === 0){
            requestBody = {...requestBody, tradeNames}
            createDrugRequest({ request, requestBody } )
        }
        else {
            requestBody = {...requestBody, id: currentEditDrug.id, tradeNameList: tradeNames}
            updateDrugRequest({ request, requestBody })
        }

        close()
    }

    return(
        <>
            <Dialog
                open={isOpen}
                onClose={() => close()}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle id="responsive-dialog-title">
                    {currentEditDrug.id === 0 ? "Добавление нового препарата" : "Изменение выбранного препарата"}
                </DialogTitle>
                <DialogContent>
                    <div className={classes.content}>
                        <TextField className={classes.inputField}
                                   label="Название препарата"
                                   name={"name"}
                                   value={currentEditDrug.name}
                                   onChange={handleChangeDescription}/>
                        <Grid
                            container
                            alignItems={'center'}
                        >
                            <Grid item>
                                <Typography>Торговое название</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={getAllTradeNames} disabled={!currentEditDrug.name.trim()}>
                                    <Search/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        {!!tradeNames.length ?
                            <div className={classes.scrollableDiv}>
                                {tradeNames.map((elem) => <Typography>{elem}</Typography>)}
                            </div>:
                            <>
                                <Typography>Торговых названий не найдено</Typography>
                            </>}
                        <TextField className={classes.inputField}
                                   label="Группа препаратов"
                                   name={"group"}
                                   value={currentEditDrug.group}
                                   onChange={handleChangeDescription}/>

                        <FormControl variant="outlined" required className={classes.selectForm}>
                            <InputLabel id="select-medType-label">Класс перепарата</InputLabel>
                            <Select
                                value={currentEditDrug.medClass?.id}
                                id="select-medType-input"
                                name={"medClass"}
                                label="Тип препарата"
                                required
                                onChange={handleChangeDescription}
                                disabled={loading && !!medClasses.length}
                            >
                                {medClasses.map(elem => (
                                    <MenuItem key={`medclass-menuitem-${elem.id}`} value={elem.id}>
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className={classes.selectForm}>
                            <InputLabel id="select-medType-label">Тип препарата</InputLabel>
                            <Select
                                value={currentEditDrug.medType}
                                id="select-medType-input"
                                name={"medType"}
                                label="Тип препарата"
                                onChange={handleChangeDescription}
                                required
                            >
                                <MenuItem value={"кардиологическое"}>
                                    кардиологическое
                                </MenuItem>

                                <MenuItem value={"онкологическое"}>
                                    онкологическое
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={() => handleSaveButtonClick()}
                            color="primary"
                            disabled={loading}
                    >
                        {currentEditDrug.id === 0 ? "Добавить" : "Сохранить"}
                    </Button>
                    <Button onClick={() => close()} color="primary" autoFocus>
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
            {snack}
        </>
    )
}