import React from "react";
import {AppBar, Toolbar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        minHeight: "3.5rem",
        flexDirection: 'row',
        flexGrow: 1
    },
    title: {
        color: '#fff',
        flexGrow: 1,
        fontSize: 26
    }
}))

export function UserNavBar() {

    const styles = useStyles()

    return(
        <div className={styles.root}>
            <AppBar className={styles.appBar} position={"fixed"}>
                <Toolbar>
                    <Typography variant={"h5"} className={styles.title}>B2DOC MED</Typography>
                </Toolbar>
            </AppBar>
        </div>
    )
}