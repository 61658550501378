// Хранение онкологических и кардиологических лекарств
import {createStore} from "effector/compat";
import {createEffect} from "effector";

// Получение препаратов с сервера
export const getDrugsRequest = createEffect( async ({request}) => {

    const drugs = await request("/api/medicine", "GET", null, btoa("420:legit"))
        .catch(() => [])

    return drugs
})

// Изменение препарата
export const updateDrugRequest = createEffect( async ({ request, requestBody }) => {

    const updatedDrug = request("/api/medicine/update", "PUT", requestBody, btoa("420:legit"))

    return updatedDrug
})

// Добавление препарата
export const createDrugRequest = createEffect( async ( {request, requestBody} ) => {

    const newDrug = await request("/api/medicine/new", "POST", requestBody, btoa("420:legit"))

    return newDrug
})

// Удаление препарата
export const removeDrugsRequest = createEffect(async ( {request, listIdRemove} ) => {
    await request("/api/medicine/delete", "DELETE", listIdRemove, btoa("420:legit"))
})

// Изменение характеристик препарата

export const $drugs = createStore([])
    .on(getDrugsRequest.doneData,(_, drugs) => drugs)
    .on(createDrugRequest.doneData, (state, newDrug) => [...state, newDrug])
    .on(updateDrugRequest.doneData, (state, updatedDrug) =>
        state.map(item => {return item.id === updatedDrug.id ? updatedDrug : item}))
    .on(removeDrugsRequest.done,(state, params) =>
            state.filter(item => !params.params.listIdRemove.includes(item.id)));