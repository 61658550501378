import React, {useState} from 'react'
import {Link, Container, TextField, Button, makeStyles} from '@material-ui/core'
import {paths} from "../../../Helpers/paths";
import {useHistory} from "react-router-dom";
import {loginAdmin, logoutAdmin} from "../../../Stores/AdminAuthorizationStore";
import Typography from "@material-ui/core/Typography";
import logo from "../../../med-logo.svg"
import {useHttp} from "../../../Hooks/useHttp";
import useMessage from "../../../Hooks/useMessage";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(18),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    submit: {
        margin: theme.spacing(2, 0, 2, 0),
        width: "90%",
    },

    enterBtn: {
        color:'#fff',
        height: "3rem",
        fontSize: 22,
        fontFamily: 'Roboto',
    },

    title: {
        fontSize: 38,
        fontFamily: 'Roboto',
        color: "#393E46",
        marginLeft: theme.spacing(1)
    },

    titleDiv:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    }
}));

export function LoginPage() {

    const classes = useStyles()
    const history = useHistory()
    const {loading, request} = useHttp()
    const [form, setForm] = useState({login:"", password:""})
    const {snack, openSnack} = useMessage()

    const handleChange = event => {
        setForm({...form, [event.target.name]:event.target.value})
    }

    const loginHandler = async () => {
        request('/users/login', 'GET', null, btoa(unescape(encodeURIComponent(`${form.login}:${form.password}`))))
            .then(() => {
                loginAdmin()
                history.push(paths.toAdminDrugs)
            })
            .catch(() => {
                logoutAdmin()
                setForm({login:"", password:""})
                openSnack("error", "Неверно указан логин или пароль!")
            })
    }


    return(
        <Container component="main" maxWidth="xs" className={classes.paper}>
            <div className={classes.titleDiv}>
                <img src={logo} className="App-logo" alt="logo" />
                <Typography className={classes.title} variant="h5">
                    B2DOC MED LOGIN
                </Typography>
            </div>

            <TextField
                variant="outlined"
                fullWidth
                onChange={handleChange}
                id="login"
                label="Логин"
                name={"login"}
                value={form.login}
                autoComplete="login"
                className={classes.submit}
            />

            <TextField
                variant="outlined"
                fullWidth
                onChange={handleChange}
                name={"password"}
                label="Пароль"
                type="password"
                id="password"
                value={form.password}
                autoComplete="current-password"
                className={classes.submit}
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                className={[classes.submit, classes.enterBtn]}
                onClick={loginHandler}
            >
                ВОЙТИ
            </Button>

            <Link href={paths.toChoice} variant="body2">
                Продолжить без авторизации
            </Link>

            {snack}
        </Container>
    )
}