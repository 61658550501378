import React, {useEffect, useState} from 'react'
import {VerticalMenu} from "../VerticalMenu";
import {Card, CardContent, makeStyles, Typography} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import {useStore} from "effector-react";
import {$drugs} from "../../../Stores/DrugsStore";
import {useHttp} from "../../../Hooks/useHttp";
import {AdminInteractionInfo} from "../AdminInteractionInfo";
import {Loader} from "../../../Helpers/Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    main: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    title: {
        marginLeft: "1.5rem",
        fontSize: 38,
        fontFamily: 'Roboto'
    },
    comboBoxesRow: {
        display: "flex",
        direction: "row",
        justifyContent: "space-between"
    },
    content: {
        marginTop: theme.spacing(4)
    }
}));

export function AdminInteractions() {

    const classes = useStyles()
    const {request, loading} = useHttp()
    const drugs = useStore($drugs);

    const [itemOncology, setItemOncology] = useState(null)
    const [itemCardiology, setItemCardiology] = useState(null)
    const [levelsInteractions, setLevelInteractions] = useState([]);

    // Получение уровней взаимодействия
    useEffect(() => {
        request("/interaction_level/", "GET", null, btoa("420:legit"))
            .then(data => {
                setLevelInteractions(data);
            }).catch(() => setLevelInteractions([]));

    }, []);

    return (
        <div className={classes.root}>
            <VerticalMenu/>
            <main className={classes.main}>
                <Typography className={classes.title}
                            variant="h3"
                            component="h3"
                            gutterBottom>
                    Взаимодействия между лекарствами
                </Typography>

                <Card elevation={1} className={classes.content}>
                    <CardContent>
                        <div className={classes.comboBoxesRow}>
                            <Autocomplete
                                id="select-oncology"
                                options={drugs
                                    .filter(item => item.medType.id === 1)
                                    .map(item => {
                                        return {id: item.id, name: item.name}
                                    })}
                                getOptionLabel={(option) => option.name}
                                style={{width: 350}}
                                value={itemOncology}
                                onChange={(event, newValue) => {
                                    setItemOncology(newValue);
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} label="Онкологические лекарства" variant="outlined"/>}
                            />

                            <Autocomplete
                                id="select-cardiology"
                                options={drugs
                                    .filter(item => item.medType.id === 2)
                                    .map(item => {
                                        return {id: item.id, name: item.name}
                                    })}
                                getOptionLabel={(option) => option.name}
                                style={{width: 350}}
                                value={itemCardiology}
                                onChange={(event, newValue) => {
                                    setItemCardiology(newValue);
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} label="Кардиологические лекарства" variant="outlined"/>}
                            />
                        </div>
                        {loading ? <Loader/> :
                            <AdminInteractionInfo MedOne={itemOncology} MedTwo={itemCardiology} levelsInteraction={levelsInteractions}/>}
                    </CardContent>
                </Card>
            </main>
        </div>
    )
}
