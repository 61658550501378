import React, {useState} from "react";
import {IconInteraction} from "./iconInterction";
import {
    makeStyles,
    Typography,
    ButtonBase,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    row:{
        display: "flex",
        direction: "row",
        margin: theme.spacing(2)
    },
    titleIcon:{
        marginLeft: theme.spacing(1.5),
        textAlign: "start"
    }
}));

export function LineIcons({levelsInteractions}){

    const [show, setShow] = useState(false);
    const [levelInteractionForModal, setLevelInteractionForModal] = useState(null);
    const styles = useStyles();

    return(
        <>
            <Dialog open={show} >
                <DialogTitle id="customized-dialog-title">
                    <div className={styles.row}>
                        <Typography variant={"h6"} className={styles.titleDialog}>
                            Подробнее
                        </Typography>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <Typography variant="body1" align={"justify"} gutterBottom>
                        {levelInteractionForModal === null? "": levelInteractionForModal.short_description}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={() => setShow(false)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={styles.row}>
                {levelsInteractions.map((value) =>
                    <ButtonBase className={styles.row} onClick={() => {
                                    setShow(true);
                                    setLevelInteractionForModal(value);
                    }}>
                        <IconInteraction levelInteractionId={value.id}/>
                        <Typography variant="body2" className={styles.titleIcon}>{value.name}</Typography>
                    </ButtonBase>
                )}
            </div>
        </>
    )
}