import React from 'react';
import {Table, TableContainer, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import {RowInteractions} from "./RowTableInteractions";
import {tableStyles} from "./TableStyles";


function createItemsTable(selectedDrugs, drugsInteractions){
    const selectedOncologyDrugs = selectedDrugs.filter(item => item.medType.id === 1);
    const selectedCardiologyDrugs = selectedDrugs.filter(item => item.medType.id === 2);

    // Ячейки заголовка таблицы (первый элемент пуст, остальные - названия кардиологических препаратов)
    const headTable = ["", ...selectedCardiologyDrugs.map(item => item.name)];

    let rowsTable = [];     // Список строк (Название онкологического препарата и список его взаимодействий)

    // Для кажого выбранного онкологического препарата...
    selectedOncologyDrugs.forEach((currentOncologyDrug) =>
    {
        let interactions = [];  // Взаимодействия текущего кардиологического препарата

        // Для каждого кардиологического препарата в порядке заголовка таблицы
        selectedCardiologyDrugs.forEach((currentCardiologyDrug) =>
            {
                // Добавить взаимодействие текущего кардиологического и онкологического
                const interactionsTemp = [...drugsInteractions
                    .filter(item => (item.medOne.id === currentCardiologyDrug.id && item.medTwo.id ===  currentOncologyDrug.id
                    || item.medOne.id === currentOncologyDrug.id && item.medTwo.id ===  currentCardiologyDrug.id))];

                interactions = [...interactions, interactionsTemp.length ===0? null: interactionsTemp[0]];
            })

        // Добавить строку
        rowsTable.push({name: currentOncologyDrug.name, interactions: interactions})
    })
    // Вернуть заголовок таблицы и ее строки
    return [headTable, rowsTable]
}

export function TableInteractions({selectedDrugs, drugsInteractions}){

    const classes = tableStyles();
    const [headTable, rowsTable] = createItemsTable(selectedDrugs, drugsInteractions);

    return(
        <>
            <TableContainer>
                <Table aria-label="caption table">

                    <TableHead>
                        <TableRow>
                            { headTable.map((cellHead) => (
                                <TableCell align={"center"} className={classes.tableCeil}>{cellHead}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rowsTable.map((row) => (
                            <RowInteractions row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}