import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React from "react";
import {selectDrug, unselectDrug} from "../../../Stores/SelectedDrugsStore";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    listItem:{
        marginTop: theme.spacing(1),
        paddingLeft: 0
    },
}));

export const DrugListItem = ({selectedRole, selectedItems, value}) => {
    const style = useStyles();
    const labelId = `checkbox-list-secondary-label-${value.name}`

    const secondaryTextTitle = selectedRole === null || selectedRole.id  === 3? "Торговое название" : "Группа препаратов"
    const secondaryTextValue = selectedRole === null || selectedRole.id  === 3? value.tradeNameList?.join(', ') : value.group

    function handleToggle(value){
        const drugSelected = selectedItems.some((item) => item.id === value.id);

        if (!drugSelected)
            selectDrug(value);
        else
            unselectDrug(value);
    }

    return (
        <ListItem className={style.listItem} button onClick={()=>handleToggle(value)}>
            <ListItemIcon>
                <Checkbox
                    edge="end"
                    checked={selectedItems.some((item) => item.id === value.id)}
                    style={{ color: 'black' }}
                    inputProps={{ 'aria-labelledby': labelId }}
                />
            </ListItemIcon>
            <ListItemText id={labelId}
                          primary={`${value.name}`}
                          secondary={`${secondaryTextTitle}: 
                                          ${secondaryTextValue && secondaryTextValue.trim() !== ""? secondaryTextValue: "не указано"}`}
            />
        </ListItem>
    )
}