import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    headCell:{
        fontStyle: 'normal',
        fontSize: 14,
        color: 'grey',
        height: '20px'
    }
}));

// Заголовки таблицы
const headCells = [
    {id: 'name', label: 'Наименование препарата', sortable: true},
    {id: 'group', label: 'Группа препарата', sortable: true},
    {id: 'tradeName', label: 'Торговое название', sortable: true},
    {id: 'medType', label: 'Тип препарата', sortable: true},
    {id: 'medClass', label: 'Класс препарата', sortable: true},
    {id: 'buttons', label: '', sortable: false}
];

export function TableDrugsHead(props) {

    const classes = useStyles()

    // Props
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all drugs'}}
                    />
                </TableCell>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.headCell}
                    >
                        {headCell.sortable && <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}