import {makeStyles} from "@material-ui/core";

export const tableStyles = makeStyles((theme) => ({
    tableCeil: {
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderStyle: "solid",
        fontSize: 17
    },
    iconButtonClass: {
        paddingLeft: 0,
        "&:hover": {
            backgroundColor: "transparent",
            cursor: "pointer"
        }
    }
}));