import React from "react";
import { Help,  CheckBox,  Warning, Cancel, ErrorOutline } from "@material-ui/icons";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    iconClass: {
        fontSize: "2rem",
    },

    questionIcon: {
        color: "#8c8c8c"
    },

    avoidIcon: {
        color: "#e54040"
    },

    reviseIcon: {
        color: "#f58251"
    },

    normalIcon:{
        color: "#2d9c1c"
    },

    observationIcon:{
        color: "#fad416"
    }
}));

export function IconInteraction({levelInteractionId}){
    const styles = useStyle();

    const iconsInteractions = [
        <Help className={[styles.iconClass, styles.questionIcon]} />,
        <CheckBox className={[styles.normalIcon, styles.iconClass]}/>,
        <ErrorOutline className={[styles.observationIcon, styles.iconClass]}/>,
        <Warning className={[styles.reviseIcon, styles.iconClass]}/>,
        <Cancel className={[styles.avoidIcon, styles.iconClass]}/>
    ];

    return (iconsInteractions[levelInteractionId-1])
}