import React from "react";
import {TableCell, TableRow} from "@material-ui/core";
import {InteractionCell} from "./InteractionCell";
import {tableStyles} from "./TableStyles";


export function RowInteractions({row}){

    const classes = tableStyles();

    return(
        <React.Fragment>
            <TableRow key={row.name}>
                <TableCell align={"center"} className={classes.tableCeil} component="th"
                           scope="row">{row.name}</TableCell>
                {row.interactions.map((interaction) => (
                    <InteractionCell interaction={interaction}/>
                ))}
            </TableRow>
        </React.Fragment>
    )
}