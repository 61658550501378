 import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {IconInteraction} from "../UserSide/iconInterction";
import {Button} from "@material-ui/core";
import {useHttp} from "../../Hooks/useHttp";
 import useMessage from "../../Hooks/useMessage";

const useStyles = makeStyles((theme) => ({
    content: {
        marginTop: theme.spacing(5),
        minHeight: "25rem"
    },
    noInteractionTitle: {
        fontSize: 24,
        fontFamily: 'Roboto',
        color: "#393E46"
    },
    interactionInfo: {
        display: "flex",
        flexDirection: "column"
    },
    inputField: {
        marginBottom: theme.spacing(4),
        paddingRight: theme.spacing(2)
    },
    selectItem: {
        display: "flex",
        alignItems: "center"
    },
    titleSelectItem: {
        marginLeft: theme.spacing(2)
    },
    divSaveBtn: {
        margin: theme.spacing(2),
    },
    saveBtn: {
        backgroundColor: '#00afb6',
        float: "right",
        color: "white",
        "&:hover": {
            backgroundColor: '#02a5ab'
        }
    }
}));

export function AdminInteractionInfo({MedOne, MedTwo, levelsInteraction}) {

    const classes = useStyles();
    const {request} = useHttp();
    const [interaction, setInteraction] = useState(null)

    const {snack, openSnack} = useMessage()

    // Изменение описаний взаимодействия
    const handleChangeDescription = event => {
        setInteraction({...interaction, [event.target.name]: event.target.value})
    }

    // Изменение уровня взаимодействия
    const handleChangeLevelInteraction = event => {
        setInteraction({...interaction, interactionLvlDto: {id: event.target.value}})
    }

    const handleSaveButtonClick = () => {

        const requestBody = {
            cardiologistDescription: interaction.cardiologistDescription,
            interactionLvlId: interaction.interactionLvlDto.id,
            medOneId: interaction.medOne.id,
            medTwoId: interaction.medTwo.id,
            oncologistDescription: interaction.oncologistDescription,
            patientDescription: interaction.patientDescription
        };

        // Если id равен 0 значит взаимодействие не было найдено ранее, значит добавить взаимодействие
        if (interaction.id === 0){

            request(`/med_interaction/new`, "POST", requestBody , btoa("420:legit"))
                .then(data => {
                    setInteraction(data)
                    openSnack("success", "Добавлено взаимодествие")
                })
                .catch((err) => {
                    openSnack("error", "Не удалось добавить взаимодействие")
                    console.log(err)
                })
        }
        // Иначе обновить описание взаимодействия
        else {
            request(`/med_interaction/update/${interaction.id}`, "PUT", requestBody, btoa("420:legit"))
                .then(() => {
                    openSnack("success", "Взаимодействие изменено")
                })
                .catch((err) => {
                    openSnack("error", "Не удалось изменить взаимодействие")
                    console.log(err)
                })
        }
    }

    // Получение взаимодействия лекарств
    useEffect(() => {
        if (MedOne && MedTwo) {
            request("/med_interaction/getMedInteractions", "POST",
                {medOneName: [MedOne.name], medTwoName: [MedTwo.name]}, btoa("420:legit"))
                .then(data => {
                    setInteraction(data[0])
                })
                .catch((err) => {
                    console.log(err)
                    // Если взаимодействие не было найдено, вернется ошибка, создаем дефолтное взаимодействие
                    setInteraction({
                        id: 0,
                        cardiologistDescription: "",
                        interactionLvlDto: {id: 1},
                        medOne: { id: MedOne.id },
                        medTwo: { id: MedTwo.id },
                        oncologistDescription: "",
                        patientDescription: ""
                    })
                })
        } else {
            setInteraction(null)
        }
    }, [MedOne, MedTwo]);


    return (
        <div className={classes.content}>
            {!interaction ?
                <Typography variant="h5" className={classes.noInteractionTitle}>
                    Выберите лекарства, чтобы найти и изменить взаимодействия
                </Typography> :

                <div className={classes.interactionInfo}>

                    <FormControl variant="outlined" className={classes.inputField}>
                        <InputLabel id="select-interaction-label">Уровень взаимодействия</InputLabel>
                        <Select
                            value={interaction ? interaction.interactionLvlDto.id : 1}
                            id="select-interaction-input"
                            name={"interactionLvlDto"}
                            label="Уровень взаимодействия"
                            onChange={handleChangeLevelInteraction}
                        >
                            {levelsInteraction.map((level) => {
                                    return (
                                        <MenuItem value={level.id}>
                                            <div className={classes.selectItem}>
                                                <IconInteraction levelInteractionId={level.id}/>
                                                <div className={classes.titleSelectItem}>
                                                    {level.name}
                                                </div>
                                            </div>
                                        </MenuItem>)
                                }
                            )}
                        </Select>
                    </FormControl>

                    <TextField className={classes.inputField}
                               label="Описание для пациента"
                               name={"patientDescription"}
                               value={interaction.patientDescription}
                               onChange={handleChangeDescription}/>

                    <TextField className={classes.inputField}
                               label="Описание для кардиолога"
                               name={"cardiologistDescription"}
                               value={interaction.cardiologistDescription}
                               onChange={handleChangeDescription}/>

                    <TextField className={classes.inputField}
                               label="Описание для онколога"
                               name={"oncologistDescription"}
                               value={interaction.oncologistDescription}
                               onChange={handleChangeDescription}/>

                    <div className={classes.divSaveBtn}>
                        <Button className={classes.saveBtn} variant="contained" onClick={handleSaveButtonClick}>
                            Сохранить
                        </Button>
                    </div>

                    {snack}
                </div>
            }
        </div>
    )
}