import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

export function DeleteDrugDialog({show, close, deleteFunc, title, message}) {

    const handleClickDelete = () => {
        deleteFunc();
        close();
    }

    return(
        <Dialog
            open={show}
            onClose={() => close()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='xs'
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>
                    Отмена
                </Button>
                <Button onClick={() => handleClickDelete()} autoFocus>
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    )
}