import React from "react";
import {$roles, $selectedRole, selectRole} from "../../../Stores/RolesStore";
import {Dialog, DialogTitle, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {useStore} from "effector-react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modalTitle:{
        marginTop: theme.spacing(2),
        textAlign: "center",
    },

    listRoles: {
        margin: theme.spacing(0, 6, 6, 6),
    },

    listItem: {
        marginTop: theme.spacing(3),
        textAlign: "center",
        color: "white",
        "&:hover": {
            backgroundColor: "#000",
            cursor: "pointer"
        }
    },

    textItem:{
        fontWeight: "bold"
    },

    patientItem:{
        backgroundColor: "#1c232d"
    },

    cardiologItem:{
        backgroundColor: "#363c44"
    },

    oncologItem:{
        backgroundColor: "#00afb6"
    }

}));

function defineColorRole(role){
    let color;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const styles = useStyles();


    switch (role.id){
        case 1:
            color = styles.oncologItem;
            break;
        case 2:
            color = styles.cardiologItem;
            break;
        case 3:
            color = styles.patientItem;
            break;
    }
    return color;
}

export function ChoiceRoleModal(){

    const styles = useStyles();

    const [open, setOpen] = React.useState(useStore($selectedRole) == null);

    let roles = useStore($roles);

    const handleListItemClick = (value) => {
        setOpen(false);
        selectRole(value);
    };

    return (
        <Dialog fullWidth maxWidth={"xs"} open={open}>
            <DialogTitle id="simple-dialog-title" className={styles.modalTitle}>
                <Typography variant={"h5"}>
                    <b>Выберите роль</b>
                </Typography>
            </DialogTitle>
            <List className={styles.listRoles}>
                {roles.map((role) => (
                    <ListItem className={styles.listItem + " " + defineColorRole(role)}
                              button
                              onClick={() => handleListItemClick(role)}
                              key={role}>
                        <ListItemText primary={role.title}  />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}