import React, {useState} from 'react';
import {Card, makeStyles} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {TableDrugsToolbar} from "./TableDrugsToolbar";
import {TableDrugsHead} from "./TableDrugsHead";
import {TableDrugsRow} from './TableDrugsRow';
import {EditDrugModal} from "../EditDrugModal";
import {removeDrugsRequest} from "../../../Stores/DrugsStore";
import {useHttp} from "../../../Hooks/useHttp";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    table: {
        minWidth: '65rem',
    },
    tableRow: {
        height: 30
    }
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

export function AdminDrugsTable({listDrugs, filterString}) {

    const classes = useStyles()
    const {request} = useHttp()

    // Сортировать (по возрастанию, по убыванию)
    const [order, setOrder] = useState('asc')

    // Поле сортировки
    const [orderBy, setOrderBy] = useState('calories')

    // Id выбранных записей
    const [selected, setSelected] = useState([])

    // Изменяемая запись
    const [editableRow, setEditableRow] = useState({group: "", id: 0, medType: "онкологическое", name: ""})

    // Номер выбранной страницы
    const [page, setPage] = useState(0)

    // Число отображаемых записей на странице
    const [rowsPerPage, setRowsPerPage] = useState(10)

    // Открыто ли окно редактирования
    const [showEditModal, setShowEditModal] = useState(false)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = listDrugs.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClickCheckbox = (idRow) => {
        const selectedIndex = selected.indexOf(idRow);

        if (selectedIndex === -1) {
            setSelected([...selected, idRow]);
        } else {
            setSelected(selected.filter(item => item !== idRow))
        }
    };

    // Перейти на следующую страницу таблицы
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Сменить число отображаемых записей на странице таблицы
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Пустые строки - строки не задействованные на странице
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, listDrugs.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Card elevation={2} className={classes.paper}>

                <EditDrugModal isOpen={showEditModal}
                               close={() => setShowEditModal(false)}
                               currentEditDrug={editableRow}
                               changeDrug={(value) => setEditableRow(value)}
                />

                <TableDrugsToolbar numSelected={selected.length}
                                   setAddMode={() => {
                                       setEditableRow({group: "", id: 0, medType: "онкологическое", name: "", tradeName: ""})
                                       setShowEditModal(true)
                                    }}

                                   deleteSelected={() => {
                                       removeDrugsRequest({request: request, listIdRemove: selected})
                                       setSelected([])
                                   }}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                    >
                        <colgroup>
                            <col style={{width: '5%'}}/>
                            <col style={{width: '10%'}}/>
                            <col style={{width: '25%'}}/>
                            <col style={{width: '15%'}}/>
                            <col style={{width: '10%'}}/>
                            <col style={{width: '5%'}}/>
                            <col style={{width: '15%'}}/>
                        </colgroup>

                        <TableDrugsHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={listDrugs?.length}
                        />
                        <TableBody>
                            {stableSort(listDrugs, getComparator(order, orderBy))
                                ?.filter(value => value.name?.toLowerCase().includes(filterString.toLowerCase()))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {

                                    const isItemSelected = (selected.indexOf(row.id) !== -1);

                                    return (<TableDrugsRow
                                        row={row}
                                        isItemSelected={isItemSelected}
                                        handleClickCheckbox={handleClickCheckbox}
                                        addEditRow={(row) => {
                                            setEditableRow(row)
                                            setShowEditModal(true)
                                        }}
                                    />);
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 50 * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={listDrugs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage={"Количество записей на странице"}
                />
            </Card>
        </div>
    );
}
