import React from "react";
import {Collapse, List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {DrugListItem} from "./DrugListItem";
import {ExpandLess, ExpandMore} from "@material-ui/icons";

export const MedClassView = ({items, selectedRole, selectedItems, opens, setOpens}) => {

    return (
        <>
            {Object.keys(items).map((key, index) => (
                <React.Fragment key={`sorted-element-${index}`}>
                    <ListItem button onClick={() => {
                        setOpens({...opens, [key]: !opens[key]})
                    }}>
                        <ListItemText primary={key}/>
                        <ListItemSecondaryAction>
                            {opens[key]? <ExpandLess /> : <ExpandMore />}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse in={opens[key]} timeout="auto" unmountOnExit>
                        <List>
                            {items[key].map((value, index) => (
                                <DrugListItem
                                    key={`sorted-drug-item-${index}-${value.name}`}
                                    selectedRole={selectedRole}
                                    value={value}
                                    selectedItems={selectedItems}
                                />
                            ))}
                        </List>
                    </Collapse>
                </React.Fragment>
            ))}
        </>
    )
}