import React from "react";
import {Container} from "@material-ui/core";
import {BrowserRouter as Router} from "react-router-dom";
import {$AdminAuthorization} from "./Stores/AdminAuthorizationStore";
import {makeStyles} from "@material-ui/core/styles";
import {useRoutes} from "./Hooks/useRoutes";
import {useStore} from "effector-react";
import { ThemeProvider } from '@material-ui/core/styles';
import {theme} from "./Helpers/ThemeProvider";

const useStyles = makeStyles((theme) => ({
    app: {
        marginTop: theme.spacing(7),
        paddingBottom: theme.spacing(3)
    }
}));

function App() {

    const styles = useStyles();
    const isAuthenticatedAdmin = useStore($AdminAuthorization)
    const routes = useRoutes(isAuthenticatedAdmin)

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Container className={styles.app}>
                    {routes}
                </Container>
            </Router>
        </ThemeProvider>

    );
}

export default App;
