import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {paths} from "../Helpers/paths";
import {AdminDrugs} from "../Components/AdminSide/AdminPages/AdminDrugs";
import {AdminInteractions} from "../Components/AdminSide/AdminPages/AdminInteractions";
import {LoginPage} from "../Components/AdminSide/AdminPages/LoginPage"
import ChoiceDrugs from "../Components/UserSide/UserPages/choiceDrugs";
import {DrugInteraction} from "../Components/UserSide/UserPages/drugInteractions";

export const useRoutes = (isAuthenticatedAdmin) => {
    return (
        <>
            {isAuthenticatedAdmin ?
                <Switch>
                    <Route path={paths.toAdminDrugs} exact>
                        <AdminDrugs />
                    </Route>
                    <Route path={paths.toAdminInteractions} exact>
                        <AdminInteractions />
                    </Route>

                    <Redirect to={paths.toAdminDrugs}/>
                </Switch>:

                <Switch>
                    <Route path={paths.toLogin} exact>
                        <LoginPage/>
                    </Route>

                    <Route path={paths.toChoice} exact>
                        <ChoiceDrugs/>
                    </Route>

                    <Route path={paths.toInteractions} exact>
                        <DrugInteraction />
                    </Route>

                    <Redirect to={paths.toChoice}/>
                </Switch>
            }
        </>
    )
}
